import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  lang: {
    locales: { es },
    current: "es"
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        background: "#C2C6CF",
        primary: "#304A6F",
        secondary: "#0000FF",
        accent: "#717698",
        error: "#FF5252",
        info: "#C1D0EF",
        success: "#101D30",
        warning: "#FFC107",
        white: "#FFFFFF",
        black: "#000000"
      }
    }
  }
});
