import paymentService from "../../services/paymentService";
export default {
  state: () => ({
    checkoutId: "",
    clientSecret: "",
    billingInfo: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: ""
    },
    paymentMethods: "",
    selectedPaymentMethod: ""
  }),
  mutations: {
    setClientSecret(state, secret) {
      state.clientSecret = secret;
    },
    setCheckoutId(state, id) {
      state.checkoutId = id;
    },
    setPaymentMethods(state, methods) {
      state.paymentMethods = methods;
    },
    setBillingInfo(state, billingInfo) {
      state.billingInfo = billingInfo;
    },
    setSelectedPaymentMethod(state, selectedPaymentMethod) {
      state.selectedPaymentMethod = selectedPaymentMethod;
    }
  },
  actions: {
    async createCheckoutSession({ commit }, body) {
      try {
        let data = await paymentService.createCheckoutSession(body);
        return commit("setClientSecret", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getCheckoutStatus({ commit }, id) {
      try {
        let data = await paymentService.getCheckoutStatus(id);
        return commit("setCheckoutId", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateBilling({ commit }, billingInfo) {
      return commit("setBillingInfo", billingInfo);
    },
    async updatePaymentMethods({ commit }) {
      let methods = await paymentService.getPaymentMethods();
      return commit("getPaymentMethods", methods);
    },
    async selectPaymentMethod({ commit }, selectedPaymentMethod) {
      return commit("setSelectedPaymentMethod", selectedPaymentMethod);
    }
  },
  getters: {
    getClientSecret(state) {
      return state.clientSecret;
    },
    getCheckoutId(state) {
      return state.checkoutId;
    },

    getPaymentMethods(state) {
      return state.paymentMethods;
    },
    getSelectedPaymentMethod(state) {
      return state.selectedPaymentMethod;
    },
    getBillingInfo(state) {
      return state.billingInfo;
    }
  }
};
