<template>
  <o-page-layout style="background-color: white !important" :mode="pageMode">
    <v-form
      style="background-color: white !important"
      @submit.prevent="onSubmitHandler"
      lazy-validation
      ref="form"
      v-model="valid"
    >
      <o-card-layout style="background-color: white !important">
        <h1 style="text-align: start; margin: 2rem">{{ title }}</h1>
        <slot />
        <template v-slot:actions>
          <v-container class="pt-0 pb-2">
            <v-row>
              <v-col cols="6" class="pb-2" v-if="secondaryButton">
                <v-btn block :to="secondaryButton.to">
                  {{ secondaryButton.label }}
                </v-btn>
              </v-col>
              <v-col
                :cols="secondaryButton ? 6 : 12"
                class="pb-2"
                v-if="submitButton"
              >
                <v-btn type="submit" block color="secondary" :loading="loading">
                  {{ submitButton.label }}
                </v-btn>
              </v-col>
              <v-col
                :cols="secondaryRightButton ? 6 : 12"
                class="pt-2 text-left"
                v-if="secondaryLeftButton"
              >
                <v-btn
                  class="o-secondary-action"
                  plain
                  small
                  :to="secondaryLeftButton.to"
                  color="black"
                >
                  {{ secondaryLeftButton.label }}
                </v-btn>
              </v-col>
              <v-col
                :cols="secondaryLeftButton ? 6 : 12"
                class="pt-2 text-right"
                v-if="secondaryRightButton"
              >
                <v-btn
                  class="o-secondary-action"
                  plain
                  small
                  :to="secondaryRightButton.to"
                  color="black"
                >
                  {{ secondaryRightButton.label }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </o-card-layout>
    </v-form>
  </o-page-layout>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    actions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    pageMode: {
      type: String,
      default: "small"
    }
  },
  setup(props) {
    const actions = props.actions;

    if (!actions || actions.length == 0) {
      throw new Error("'actions' prop required");
    }

    const submitButton = actions.find(action => action.type === "submit");

    const secondaryButton = actions.find(action => action.type === "secondary");

    const secondaryRightButton = actions.find(
      action => action.type === "secondary-right"
    );
    const secondaryLeftButton = actions.find(
      action => action.type === "secondary-left"
    );

    if (!submitButton) {
      throw new Error("Submit button required");
    }

    return {
      submitButton,
      secondaryButton,
      secondaryRightButton,
      secondaryLeftButton
    };
  },
  data() {
    return {
      valid: true
    };
  },
  methods: {
    onSubmitHandler() {
      this.submitButton.handler(this.$refs.form);
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
});
</script>

<style>
.o-secondary-action {
  text-transform: none;
  font-weight: 700;
}
.o-secondary-action:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(
    :hover
  )
  .v-btn__content {
  opacity: 0.75;
}
</style>
