import productService from "../../services/productService";

export default {
  state: () => ({
    products: [],
    categories: [],
    selectedProduct: {}
  }),
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setSelectedProduct(state, selectedProduct) {
      state.selecteProduct = selectedProduct;
    }
  },
  actions: {
    async updateProducts({ commit }) {
      try {
        let data = await productService.getProducts();
        return commit("setProducts", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateCategories({ commit }) {
      try {
        let data = await productService.getProductsCategories();
        return commit("setCategories", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    selecteProduct({}, selectedProduct) {
      return;
    }
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getCategories(state) {
      return state.categories;
    },
    getSelectedProduct(state) {
      return state.selectedProduct;
    }
  }
};
