<template>
  <v-app id="ovaldisc">
    <v-app-bar v-if="isUserAuthenticated" app clipped-left dark height="80">
      <!-- <img
        class="menu-class"
        button
        style="color: white"
        src="./assets/logo-hamburguer.svg"
        @click.stop="drawer = !drawer"
        alt=""
      /> -->
      <div style="width: 5rem">
        <v-icon button style="color: white" src="./assets/logo-hamburguer.svg" @click.stop="drawer = !drawer">fas
          fa-list</v-icon>
      </div>
      <img alt="Global DISC" class="mt-2" height="60" src="./assets/logo3.svg" />
      <v-spacer></v-spacer>
      <div class="font-weight-bold pr-4 whiteText" v-if="!isXS && isUserAuthenticated">
        {{ user.displayName }}<br />
        {{ user.email }}
      </div>
      <v-menu left bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="display: none;" color="#304A6F" v-bind="attrs" v-on="on">
            <img src="./assets/user-icon.svg" alt="" />
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-for="item in userItems" :key="item.title" @click="item.handler()">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon color="secondary" v-if="showMenu" @click="toggleReportMenu()">
        <v-icon>fas fa-list</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="isUserAuthenticated" v-model="drawer" clipped app>
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in drawerItems" :key="item.title" @click="item.handler()">
          <v-list-item-icon>
            <v-icon style="color: black;">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="d-flex align-content-center flex-wrap">
      <v-sheet class="overflow-hidden" style="position: relative">
        <router-view @open-dialog="openDialog" @set-floating-action="setFloatingAction"></router-view>
      </v-sheet>
    </v-main>
    <v-dialog width="200" v-model="processingRequest" persistent>
      <v-card>
        <v-card-title class="text-h6"> Procesando... </v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <o-message-dialog ref="messageDialog" />
    <!-- footer container -->
    <div class="bg-grey-lighten-1 flex-column footer-class-container">
      <div class="container-icons-for-footer">
        <div class="d-flex justify-end background-white segment-for-footer" fluid>
          <div class="d-flex element-info">
            <div>
              <img class="icons-width" src="./assets/Ubicacion.png" alt="" />
            </div>
            <div>
              <div class="color-infos">Ubicacion</div>
              <div>Direccion aqui</div>
            </div>
          </div>
          <div class="d-flex element-info">
            <div>
              <img class="icons-width" src="./assets/Telefono.png" alt="" />
            </div>
            <div>
              <div class="color-infos">Telefono</div>
              <div>000 000 0000</div>
            </div>
          </div>
          <div class="d-flex element-info">
            <div>
              <img class="icons-width" src="./assets/Correo.png" alt="" />
            </div>
            <div>
              <div class="color-infos">Correo</div>
              <div>correo@ejemplo.com</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-background">
        <div class="display-data-footer-icons">
          <div class="container-general-info">
            <div style="margin-top: 3rem; margin-right: 5rem">
              <img src="./assets/higher-horizontal-logo.png" alt="" />
              <p style="width: 15rem; color: white">
                Higher Up Coaching es una empresa líder en coaching dedicada a
                potenciar a individuos y organizaciones para alcanzar su máximo
                potencial
              </p>
              <div class="icon-network-container">
                <img class="icon-network" src="./assets/facebook.png" alt="" />
                <img class="icon-network" src="./assets/instagram.png" alt="" />
                <img class="icon-network" src="./assets/whatsapp.png" alt="" />
              </div>
            </div>
            <div class="container-ul" style="margin-top: 3rem; margin-right: 5rem; margin-left: 5rem">
              <ul type="none" class="list-ul">
                <h3>Paginas</h3>
                <li @click="navigateTo('/')">
                  <img src="./assets/right-row.png" alt="" /> Inicio
                </li>
                <li @click="navigateTo('/sobre-nosotros')">
                  <img src="./assets/right-row.png" alt="" /> Sobre Nosotros
                </li>
                <li @click="navigateTo('/contacto')">
                  <img src="./assets/right-row.png" alt="" /> Contáctanos
                </li>
                <li @click="navigateTo('/tyc')">
                  <img src="./assets/right-row.png" alt="" /> Términos y
                  Condiciones
                </li>
                <li @click="navigateTo('/politicas')">
                  <img src="./assets/right-row.png" alt="" /> Políticas de
                  Privacidad
                </li>
              </ul>
            </div>
            <div class="container-ul">
              <ul type="none" class="list-ul">
                <h3>Servicios</h3>
                <li @click="navigateTo('/servicios/corporativos')">
                  <img src="./assets/right-row.png" alt="" /> Corporativos
                </li>
                <li @click="navigateTo('/servicios/individuales')">
                  <img src="./assets/right-row.png" alt="" /> Individuales
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex terms-and-conditions-class">
          <div class="d-flex politics">
            <p style="font-size: small; margin-top: 0.6rem; margin-right: 45rem" class="copy">
              Todos los derechos reservados | Higher Up Coaching 2024
            </p>
            <p style="font-size: small; margin-top: 0.5rem" @click="navigateTo('/politicas')">
              Politicas de privacidad
            </p>
            <p style="font-size: small; margin-top: 0.5rem; margin-left: 1rem" @click="navigateTo('/tyc')">
              Terminos y Condiciones
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import packageJson from "../package.json";
import reportMenuItems from "@/model/assessment/reportTOC";
export default {
  components: {
  },
  computed: {
    // TODO: Refactor to a mixin
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    isXS() {
      return this.$vuetify.breakpoint.name == "xs";
    },
    processingRequest() {
      return this.$store.getters.processingRequest;
    },
    showFloatingAction() {
      return this.isXS && this.floatingAction;
    },
    showMenu() {
      return this.$route.name === "Report";
    },
    user() {
      return this.$store.getters.user;
    }
  },
  created() {
    this.openMenu = this.$vuetify.breakpoint.smAndUp;
    this.version = "v" + packageJson.version;
    this.year = new Date().getFullYear();
  },
  data() {
    return {
      drawer: null,
      drawerItems: [
        {
          title: "Estado de la membresía",
          icon: "fas fa-tachometer-alt",
          handler: () => {
            this.$router.push("/");
          }
        },
        {
          title: "Cerrar session",
          icon: "fas fa-sign-out-alt",
          handler: this.signOut
        }
      ],
      userItems: [
        {
          title: "Cambiar contrasena",
          icon: "fas fa-key",
          handler: () => {
            this.$router.push("/change-password");
          }
        },
        {
          title: "Cerrar session",
          icon: "fas fa-sign-out-alt",
          handler: this.signOut
        }
      ],
      openMenu: true,
      reportMenuItems: reportMenuItems,
      version: null,
      year: null,
      floatingAction: null
    };
  },
  methods: {
    onToggleCollapse(collapsed) { },
    onItemClick(event, item, node) { },
    goTo(item) {
      const el = document.getElementById(item.id);
      window.scrollTo({
        top: el.offsetTop,
        behavior: "auto"
      });
      if (this.isXS) {
        this.openMenu = false;
      }
    },
    signOut() {
      this.$store.dispatch("signUserOut").catch(error => {
        this.openDialog("error", error.message);
      });
    },
    openDialog(opts) {
      this.$refs.messageDialog.open(opts);
    },
    toggleReportMenu() {
      this.openMenu = !this.openMenu;
    },
    setFloatingAction(action) {
      this.floatingAction = action;
    }
  },
  watch: {
    isUserAuthenticated(value) {
      if (!!value) {
        if (this.$store.getters.redirectRoute) {
          this.$router.push(this.$store.getters.redirectRoute.path);
          this.$store.dispatch("clearRedirectRoute");
        }
      } else {
        this.$router.push("/signin");
      }
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Harabara Mais";
  src: url("~@/assets/fonts/Harabara Mais Demo.otf");
}

.menu-class {
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 1rem;
}

.tabs-custom {
  padding: 0 4px;
  /* Reduce el padding horizontal a 4 píxeles */
  min-width: 0;
  /* Permite que los tabs se ajusten al contenido */
}

.tab-text {
  margin: 0;
  /* Elimina el margen predeterminado */
  font-size: 10px;
  /* Ajusta el tamaño de fuente según tus necesidades */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.tabs-class {
  width: 60%;
}

.politics {
  margin: auto;
  justify-content: flex-end;
  display: flex;
  margin-top: 2.8rem !important;

  p {
    cursor: pointer;
    color: white !important;
  }

  p:hover {
    text-decoration: underline;
  }
}

.copy {
  margin-left: 8rem;
  margin-top: 3rem;
  color: white;
}

.element-info {
  margin-right: 5rem;
  margin-top: 1rem;
}

.color-infos {
  color: #5e5e5e;
}

.icons-width {
  margin-right: 1rem;
  width: 2.5rem;
}

.container-ul {
  margin-top: 4rem !important;
}

.list-ul {
  color: white;

  li {
    cursor: pointer;
    margin: 9px;
  }

  li:hover {
    text-decoration: underline;
  }
}

.container-general-info {
  justify-content: flex-end;
  margin: auto;
  display: flex;
  margin-right: 10rem;

  div {
    margin: 1rem 2rem 0rem 0rem;
  }
}

.card-component {
  margin-left: 5rem;
  bottom: -1.45rem;
}

.background-white {
  background-color: white;
  height: 6rem;
}

.container-background {
  background-color: #101d30;
  margin-top: -15rem;
  height: 25rem;
  width: 100%;
}

.white-background {
  background-color: #ffffff !important;
}

.o-footer>div {
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
}

.icon-network {
  margin: 1rem 1rem 0rem 0rem;
  cursor: pointer;
}

.icon-network-container {
  justify-content: flex-start;
  margin: auto;
  display: flex;
  padding-left: 0%;
}

.o-opacity {
  opacity: 0.9;
}

.footer-form {
  margin-top: -119px;
}

.o-menu-item {
  white-space: normal;
}

.v-card__title {
  word-break: normal !important;
}

@media screen {
  .no-screen {
    display: none !important;
  }

  .theme--light.v-application {
    /* background-color: #37526d !important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }

  .od-mt-16 {
    margin-top: 16px !important;
  }
}

@media print {

  .no-print,
  nav,
  header,
  footer {
    display: none !important;
  }

  main {
    padding-left: 0rem !important;
  }

  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
}

.v-messages__message {
  font-weight: 600;
}

.v-slide-group__content,
.v-tabs-bar__content {
  background-color: #ffffff;
}

p {
  color: black;
  padding-top: 1rem;
}

.tabs-group-class {
  justify-content: flex-end;
  margin: auto;
  display: flex;
}

.comience-su-viaje-de-liderazgo-atrevido {
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.94px;
  text-align: left;
}

.terms-and-conditions-class {
  position: absolute;
  bottom: 0rem;
}

.display-data-footer-icons {
  margin: auto;
  position: absolute;
  right: 10rem;
}

.container-icons-for-footer {
  position: relative;
  bottom: 14.5rem;
}

.segment-for-footer {
  margin-top: 13rem;
}

.navbarTitle {
  background-image: url("assets/logo3.svg");
  height: 100%;
  width: 10rem;
}

/* .v-app-bar--is-scrolled {
  & .navbarTitle {
    background-image: url("assets/logo3.svg");
  }

  & #line,
  & #secondMenu,
  & .tabs-class {
    display: none !important;
  }

  & .v-toolbar__content {
    background-color: transparent;
  }
}

.v-toolbar {
  background-color: transparent !important;
}

.v-app-bar {
  & .v-app-bar--is-scrolled {
    background-color: transparent;
  }
} */

.headerLogo {
  padding: 5px;
  height: 100%;
}

.tabs-custom {
  padding: 0 4px;
  /* Reduce el padding horizontal a 4 píxeles */
  min-width: 0;
  /* Permite que los tabs se ajusten al contenido */
}

.tab-text {
  margin: 0;
  /* Elimina el margen predeterminado */
  font-size: 12px;
  /* Ajusta el tamaño de fuente según tus necesidades */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.tabs-class {
  width: 60%;
}

.politics {
  margin: auto;
  justify-content: flex-end;
  display: flex;
  margin-top: 2.8rem !important;

  p {
    cursor: pointer;
    color: white !important;
  }

  p:hover {
    text-decoration: underline;
  }
}

.copy {
  margin-left: 8rem;
  margin-top: 3rem;
  color: white;
}

.element-info {
  margin: auto;
  margin-top: 1.5rem;
}

.color-infos {
  color: #5e5e5e;
}

.icons-width {
  margin-right: 1rem;
  width: 2.5rem;
}

.container-ul {
  margin-top: 4rem !important;
}

.list-ul {
  color: white;

  li {
    cursor: pointer;
    margin: 9px;
  }

  li:hover {
    text-decoration: underline;
  }
}

.container-general-info {
  margin: auto;
  display: flex;
  margin-right: 10rem;
}

.card-component {
  margin-left: 10rem;
  bottom: -1.45rem;
}

.background-white {
  background-color: white;
  height: 6rem;
}

.container-background {
  background-color: #001e8c;
  margin-top: -15rem;
  height: 25rem;
  width: 100%;
}

.white-background {
  background-color: #ffffff !important;
}

.o-footer>div {
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
}

.icon-network {
  margin: 1rem 1rem 0rem 0rem;
  cursor: pointer;
}

.icon-network-container {
  justify-content: flex-start;
  margin: auto;
  display: flex;
  padding-left: 0%;
}

.o-opacity {
  opacity: 0.9;
}

.whiteText {
  color: white;
}

.footer-form {
  margin-top: -119px;
}

.o-menu-item {
  white-space: normal;
}

.v-card__title {
  word-break: normal !important;
}

@media screen {
  .no-screen {
    display: none !important;
  }

  .theme--light.v-application {
    /* background-color: #37526d !important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }

  .od-mt-16 {
    margin-top: 16px !important;
  }
}

@media print {

  .no-print,
  nav,
  header,
  footer {
    display: none !important;
  }

  main {
    padding-left: 0rem !important;
  }

  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
}

.v-messages__message {
  font-weight: 600;
}

.v-slide-group__content,
.v-tabs-bar__content,
.v-toolbar__content {
  background-color: #001e8c;
}

p {
  color: black;
  padding-top: 1rem;
}

.transparent-background {
  background-color: transparent !important;
}

.white-background {
  background-color: white !important;
}

.tabs-custom {
  padding: 0 4px;
  min-width: 0;
}

.v-tabs-slider {
  background-color: white;
}

.tab-text {
  margin: 0;
  font-size: 10px;
  color: white;
  white-space: nowrap;
}

/* Asegúrate de que los elementos dentro de v-tabs sean transparentes cuando sea necesario */
.transparent-background>>>.v-tabs-bar {
  background-color: transparent !important;
}

.transparent-background>>>.v-tab {
  background-color: transparent !important;
}

@media screen and (min-width: 768px) {
  .responsive-mobile-app-bar {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .footer-class-container {
    display: none;
  }

  .container-display-for-desktop-responsive {
    display: none;
  }

  .container-app-bar-styling {
    display: none;
  }

  .tabs-class {
    display: none !important;
  }

  .navbarTitle {
    height: 80%;
    width: 5rem;
  }

  .class-for-responsive1 {
    display: none;
  }
}

.logo-for-mobile-responsive-horizontal {
  width: 5rem;
  height: 2.5rem;
}
</style>
