<template>
  <div style="margin: auto; display: flex; justify-content: center">
    <o-card-form-layout
      ref="cardForm"
      :title="title"
      :actions="actions"
      page-mode="wide"
    >
      <div style="display: flex">
        <v-container class="pb-0">
          <div style="max-width: 800px; justify-content: center; margin: auto">
            <div style="display: block; justify-content: center; margin: auto">
              <div style="">
                <div
                  style="
                    display: flex;
                    margin: auto;
                    justify-content: flex-start;
                  "
                >
                  <img src="../../assets/fomr-icon.svg" alt="" />
                  <h1
                    style="
                      text-align: start;
                      margin: auto;
                      margin-left: 0rem;
                      font-family: Harabara Mais;
                    "
                  >
                    Enviar prueba Disc
                  </h1>
                </div>
              </div>
              <h4
                style="text-align: start; margin: 1rem; font-family: montserrat"
              >
                Datos del aplicante
              </h4>
              <div style="display: flex; width: 100%; justify-content: center">
                <div style="width: 50%; margin: 1rem">
                  <o-email-field
                    v-model="form.applicantEmail"
                    label="Correo Electrónico del Aplicante"
                    :disabled="isStatusSubmitted"
                    @keydown="syncApplicantEmail"
                    @change="syncApplicantEmail"
                  />
                </div>
                <div style="width: 50%; margin: 1rem">
                  <o-text-field
                    v-model="form.applicantName"
                    label="Nombre(s) y Apellido(s)"
                    required
                    :disabled="isStatusSubmitted"
                    @keydown="syncApplicantName"
                    @change="syncApplicantName"
                  />
                </div>
              </div>
              <div>
                <div style="display: flex; margin: auto; width: 97%">
                  <o-text-field
                    v-model="form.company"
                    label="Empresa (opcional)"
                    :disabled="isStatusSubmitted"
                  />
                </div>
                <div style="display: flex; margin: auto; width: 97%">
                  <o-text-field
                    v-model="form.position"
                    label="Cargo (opcional)"
                    :disabled="isStatusSubmitted"
                  />
                </div>
              </div>
            </div>
            <div
              style="display: flex; justify-content: flex-start; margin: 1rem"
            >
              <h4 style="font-family: montserrat">Datos del receptor</h4>
            </div>
            <div style="margin-left: 1rem; display: flex">
              <v-checkbox
                v-model="form.applicantReceives"
                label="Usar datos del aplicante"
              />
            </div>
            <div style="display: flex; width: 100%">
              <div style="width: 50%; margin: 1rem">
                <o-email-field
                  v-model="form.receiverEmail"
                  label="Correo Electrónico del Receptor"
                  required
                  :disabled="form.applicantReceives"
                />
              </div>
              <div style="width: 50%; margin: 1rem">
                <o-text-field
                  v-model="form.receiverName"
                  label="Nombre(s) y Apellido(s) del Receptor"
                  required
                  :disabled="form.applicantReceives"
                />
              </div>
            </div>
            <div style="display: flex; justify-content: start; margin: 1rem">
              <v-btn
                style="
                  height: 47px;
                  width: 196px;
                  margin: 0rem;
                  font-size: 14px;
                "
                color="secondary"
                @click="sendForm"
                >Enviar</v-btn
              >
            </div>
          </div>
        </v-container>
        <v-btn
          style="
            height: 47px;
            width: 196px;
            margin: 0rem;
            font-size: 14px;
            margin-top: 2rem;
          "
          color="secondary"
          @click="back"
          >Volver Atrás</v-btn
        >
      </div>
    </o-card-form-layout>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      mode: null,
      form: {
        applicantEmail: "",
        applicantName: "",
        company: "",
        position: "",
        applicantReceives: false,
        receiverEmail: "",
        receiverName: ""
      },

      actions: [
        { type: "submit", label: "Enviar", handler: this.send },
        {
          type: "secondary",
          label: "Volver Atrás",
          to: "/"
        }
      ]
    };
  },
  computed: {
    isModeSend() {
      return this.mode === "SEND";
    },
    isModeResend() {
      return this.mode === "RESEND";
    },
    isStatusSubmitted() {
      return this.assessment.status === "SUBMITTED";
    },
    title() {
      if (this.mode === "SEND") {
        return "Enviar Prueba DISC";
      } else if (this.mode === "RESEND") {
        if (this.assessment.status === "SENT") {
          return "Reenviar Prueba DISC";
        } else if (this.assessment.status === "SUBMITTED") {
          return "Reenviar Reporte DISC";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    successMessage() {
      if (this.mode === "SEND") {
        return "La prueba ha sido enviada con éxito";
      } else if (this.mode === "RESEND") {
        if (this.assessment.status === "SENT") {
          return "La prueba ha sido enviada con éxito";
        } else if (this.assessment.status === "SUBMITTED") {
          return "El reporte ha sido enviado con éxito";
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    sendForm() {
      const cardForm = this.$refs.cardForm;
      if (cardForm.validate()) {
        const accountInfo = this.$store.getters.accountInfo;
        const user = this.$store.getters.user;
        if (accountInfo && user) {
          let promise = null;
          if (this.isModeSend) {
            promise = this.$store.dispatch("createAssessment", {
              ...this.form,
              accountId: accountInfo.id,
              sentBy: user.id
            });
          } else if (this.isModeResend) {
            for (let attr in this.form) {
              this.assessment[attr] = this.form[attr];
            }
            promise = this.$store.dispatch("resendAssessment", {
              assessment: this.assessment,
              assessmentId: this.assessmentId
            });
          }

          promise
            .then(assessmentId => {
              this.successDialog(this.successMessage, () => {
                if (this.isModeSend) {
                  cardForm.reset();
                } else if (this.isModeResend) {
                  this.back();
                }
              });
            })
            .catch(error => {
              this.errorDialog(
                "Ha ocurrido un error en el envío de la prueba",
                this.back
              );
            });
        } else {
          this.errorDialog(
            "Información de Cuenta o de Usuario inválida",
            this.back
          );
        }
      }
    },
    resetForm() {
      this.form.applicantEmail = "";
      this.form.applicantName = "";
      this.form.company = "";
      this.form.position = "";
      this.form.applicantReceives = false;
      this.form.receiverEmail = "";
      this.form.receiverName = "";
    },
    syncApplicantEmail() {
      if (this.form.applicantReceives) {
        this.form.receiverEmail = this.form.applicantEmail;
      }
    },
    syncApplicantName() {
      if (this.form.applicantReceives) {
        this.form.receiverName = this.form.applicantName;
      }
    },
    init() {
      if (this.isModeResend) {
        this.loadAssessment()
          .then(assessment => {
            this.form.applicantEmail = assessment.applicantEmail;
            this.form.applicantName = assessment.applicantName;
            this.form.applicantReceives = assessment.applicantReceives;
            this.form.company = assessment.company;
            this.form.position = assessment.position;
            this.form.receiverEmail = assessment.receiverEmail;
            this.form.receiverName = assessment.receiverName;
          })
          .catch(error => {
            this.$store.dispatch("clearAssessment");
            this.showErrorAndBack(
              "Ha ocurrido un error leyendo los datos de la prueba"
            );
          });
      }
    }
  },
  watch: {
    "form.applicantReceives": function (value) {
      if (value) {
        this.form.receiverEmail = this.form.applicantEmail;
        this.form.receiverName = this.form.applicantName;
      } else {
        this.form.receiverEmail = "";
        this.form.receiverName = "";
      }
    }
  },
  created() {
    if (this.assessmentId) {
      this.mode = "RESEND";
    } else {
      this.mode = "SEND";
    }
    this.init();
  }
});
</script>

<style></style>
