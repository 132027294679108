import store from "@/store/index.js";
import messages from "@/plugins/messages";

export default {
  beforeRouteEnter(to, from, next) {
    try {
      store
        .dispatch("getAccountInfo")
        .then(accountInfo => {
          console.log(accountInfo);
          if (accountInfo) {
            /* if (accountInfo.billingInfo) {
              if (accountInfo.purchaseRequired) {
                next("/shop/membership");
              } else {
                next(true);
              }
            } else {
              next("/billing-info/onboard");
            } */
            if (accountInfo.purchaseRequired) {
              next("/shop/membership");
            } else {
              next(true);
            }
          } else {
            next(`/error/${encodeURI(messages.errors.invalidAccountInfo)}`);
          }
        })
        .catch(error => {
          console.log(error);
          next(`/error/${encodeURI(messages.errors.invalidAccountInfo)}`);
          throw error;
        });
    } catch (error) {
      console.log(error);
    }
  }
};
